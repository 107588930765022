@use 'common'
@use 'sass:math'

.Wrapper
	//

.Title
	font-size: 52px
	line-height: 60px
	letter-spacing: -0.02em
	margin: 2rem 0
	text-align: center
