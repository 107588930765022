@use 'common'
@use 'sass:math'

.Slides
	+common.hideHorizontalScrollBar
	display: flex
	overflow-x: auto
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	height: 75vh

.Slide
	display: flex
	flex-direction: column
	width: 100%
	flex-shrink: 0
	scroll-snap-align: center

.Image
	display: flex
	justify-content: center
	position: relative
	height: 100%
	line-height: 0

.Indexes
	position: absolute
	bottom: 1rem
	display: flex
	justify-content: center
	width: 100%
	@media (min-width: common.$break48)
		bottom: 3rem

.Index
	margin: 0.25rem
	font-size: 0.75rem
	border: 1px solid #fff
	border-radius: 50%
	aspect-ratio: 1
	background-color: transparent
	color: #fff
	&:hover
		border-color: common.$red
		color: common.$red

.isActive
	border-color: common.$red
	color: common.$red

.ContentLink
	text-decoration: none

	&:hover
		.Text
			text-decoration: underline
			color: white

.Content
	background-color: common.$red
	padding: 2rem 1.75rem 3rem
	@media (min-width: common.$break48)
		padding: 2rem 3.5rem 3rem
	@media (min-width: common.$break62)
		padding: 2rem 7rem 3rem

.Description
	font-weight: 600
	font-size: 10px
	line-height: 13px
	letter-spacing: 0.07em
	text-transform: uppercase
	margin-bottom: 0.5rem
	@media (min-width: common.$break48)
		font-size: 12px
		line-height: 15px

.Text
	font-family: var(--font-georgia)
	font-weight: 400
	font-size: 26px
	line-height: 30px
	letter-spacing: -0.02em
	color: #fff
	@media (min-width: common.$break48)
		font-size: 39px
		line-height: 45px
	@media (min-width: common.$break75)
		font-size: 3rem
		line-height: 3.375rem
