@use 'common'
@use 'sass:math'

.Wrapper
	//

.TitleWrapper
	display: flex
	justify-content: space-between
	border-top: 1px solid

.Title
	font-family: var(--font-georgia)
	font-style: italic
	font-size: 32px
	line-height: 36px
	letter-spacing: -0.02em
	margin-top: 6px

.Link
	font-weight: 500
	font-size: 14px
	line-height: 28px
	color: common.$red
	text-decoration: none
	&:hover
		text-decoration: underline

.NewsPosts
	width: 100%
	display: grid
	border-bottom: 1px solid #D9D9D9
	padding-bottom: 2.625rem
	@media (min-width: common.$break48)
		grid-template-columns: repeat(2, 1fr)

	@media (min-width: common.$break62)
		grid-template-columns: repeat(var(--Newspost-columnCount), 1fr)

.NewsPost
	width: 100%
	border-top: 1px solid #D9D9D9
	&:nth-child(1)
		border-left: none
	@media (min-width: common.$break62)
		border-top: none
		border-left: 1px solid #D9D9D9
