@use 'common'

.Wrapper
	display: flex
	justify-content: center
	margin-top: 1rem

.Breadcrumb
	display: flex
	align-items: center
	font-weight: 500
	font-size: 12px
	line-height: 16px
	text-decoration: none
	margin-right: 1rem
	&:hover
		text-decoration: underline

	&:nth-last-child(1)
		.Icon
			display: none

.Icon
	display: flex
	margin-left: 1rem
