@use 'common'

.wrapper
	display: grid
	width: fit-content
	margin: auto

	img
		width: 100%
		height: auto

.description
	font-weight: 500
	font-size: 12px
	line-height: 16px
	text-align: right
	color: #8B8B8B
