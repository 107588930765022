@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	flex-direction: column
	align-items: center

.Grants
	width: 100%
	display: flex
	flex-wrap: wrap
