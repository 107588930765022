@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	align-items: stretch
	width: 100%
	@media (min-width: common.$break48)
		width: 50%
	@media (min-width: common.$break62)
		width: math.div(100%, 3)

.Container
	display: flex
	flex-direction: column
	margin: 1rem 0
	border-radius: 3px
	overflow: hidden
	width: 100%
	@media (min-width: common.$break48)
		margin: 1rem 1.75rem

.WrapperIn
	display: flex
	flex-direction: column
	justify-content: space-between
	padding: .75rem
	height: 100%

.Type
	background-color: rgba(237, 67, 61, 0.1)
	color: common.$red
	text-transform: uppercase
	padding: .25rem .5rem
	font-weight: 600
	font-size: 12px
	line-height: 15px
	letter-spacing: 0.07em

.Name
	font-weight: 700
	font-size: 32px
	line-height: 36px
	letter-spacing: -0.02em

.LeadParagraph
	padding: 1.25rem 0

.Date
	font-size: 20px
	line-height: 28px
	letter-spacing: -0.01em
	margin-bottom: 3.5rem

.Button
	display: flex
	margin-left: auto
	padding: 1rem 1.25rem
	border: 1px solid
	border-radius: 5px
	width: fit-content
	font-weight: 600
	font-size: 16px
	line-height: 19px
	text-decoration: none
