.Wrapper
	position: relative
	display: flex
	align-items: center

	&:hover, &:focus
		.Check
			box-shadow: 0 0 .125rem .125rem rgba(#000000, 0.2)

.Check
	width: 1.75rem
	aspect-ratio: 1
	border: .0625rem solid #000000
	flex-shrink: 0
	border-radius: .1875rem
	display: flex
	align-items: center
	justify-content: center
	transition-property: background-color
	transition-timing-function: ease-out
	transition-duration: 0.2s

	.view_labelPosition_inline &
		&::after
			content: ""
			font-size: .75rem
			border-bottom: .125rem solid #ffffff
			border-left: .125rem solid #ffffff
			width: 1em
			height: .5em
			transform: rotate(-45deg)
			margin-top: -0.125em

.Input
	visibility: hidden
	position: absolute

	&:checked ~ .Check
		background-color: #000000

	.view_labelPosition_inside &
		&:checked ~ .Label
			color: #ffffff

.Label
	user-select: none
	transition-property: color
	transition-timing-function: ease-out
	transition-duration: 0.2s

	.view_labelPosition_inline &
		margin-left: .75rem

	.view_labelPosition_inside &
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		font-weight: 600

