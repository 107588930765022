@use 'common'
@use 'sass:color'

.wrapper
	a
		font-weight: 600
		color: common.$red
		transition: color 0.2s

		&:hover, &:focus-visible
			color: color.adjust(common.$red, $lightness: 15%)

	h1
		font-family: var(--font-georgia)
		font-weight: 400
		font-size: 52px
		line-height: 60px
		letter-spacing: -0.02em

	h2
		font-family: var(--font-georgia)
		font-weight: 400
		font-size: 32px
		line-height: 40px
		letter-spacing: -0.02em

	h3
		font-weight: 700
		font-size: 32px
		line-height: 36px
		letter-spacing: -0.02em

	p
		font-weight: 400
		font-size: 20px
		line-height: 28px
		letter-spacing: -0.01em

	em
		font-style: normal
		color: common.$red

	[data-contember-align="center"]
		text-align: center

	[data-contember-align="end"]
		text-align: end
