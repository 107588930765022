.Wrapper
	font-size: .75rem
	font-weight: 600
	border: .0625rem solid #000000
	flex-grow: 1

.Values
	display: flex

.Value
	flex-basis: 100%
	padding: .3125rem 1rem
	line-height: 1
	background-color: var(--MediaRatingLegend-value-color)

.Label
	padding: 0.5rem 1rem 1.5rem
