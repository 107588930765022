@use 'common'

.LinkWrapper,
.FileWrapper
	display: flex
	align-items: center

.LinkTitle
	font-weight: 700
	font-size: 1.5625rem
	line-height: 1.8125rem
	letter-spacing: -0.02em

.FileLinkDownload
	background-color: #32373c
	border-radius: 2em
	color: #fff
	font-size: 13px
	padding: 0.5em 1em
	margin-left: 0.75rem
