@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	flex-wrap: wrap

.Column
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	width: 100%
	background-color: transparent
	border: 1px solid common.$red
	color: common.$red
	font-size: 1.25rem
	padding: 1.875rem .9375rem
	text-decoration: none
	margin: .5rem
	border-radius: 3px
	&:hover
		background-color: common.$red
		color: #fff

	@media (min-width: common.$break48)
		width: calc(100% / 3 - 60px)
		padding: 3.75rem 1.875rem
		margin: 1.875rem
	@media (min-width: common.$break62)
		font-size: 2rem

.isActive
	background-color: common.$red
	color: #fff

.Description
	font-size: .75rem
	line-height: 1.25rem

	text-align: center
	letter-spacing: -0.01em
	@media (min-width: common.$break62)
		font-size: 1.25rem
		line-height: 1.75rem

.Content
	display: none
	margin-top: 2rem
	@media (min-width: common.$break48)
		margin-left: 1.875rem

.showContent
	display: block
	// flex-direction: column

.Image
	margin-top: 1rem
