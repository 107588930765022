@use 'common'
@use 'sass:math'

.ShareIcons
	display: flex
	justify-content: center

.ShareIcon
	display: flex
	justify-content: center
	margin: 0 1rem 3.4375rem

	&.is_hidden
		opacity: 0
		visibility: hidden

.ShareBtn
	font-size: 1.25rem
	background-color: transparent
	border: none
