@use 'common'
@use 'sass:math'

.Categories
	display: flex
	flex-wrap: wrap
	justify-content: center
	margin: 1rem 0 6.25rem

.Category
	font-weight: 600
	font-size: 12px
	line-height: 15px
	text-align: center
	letter-spacing: -0.02em
	color: #F8F6EE
	background-color: #000
	border-radius: 2px
	padding: 10px 16px
	margin: 6px
	cursor: pointer
	text-decoration: none

	&:hover
		box-shadow: inset 0 0 0 4px common.$red

	&.active
		box-shadow: inset 0 0 4px common.$red
		background-color: common.$red
