@use 'common'
@use 'sass:math'

.Boxes
	display: flex
	flex-direction: column
	padding-bottom: 3rem
	@media  (min-width: common.$break62)
		margin: 0 -1.875rem
		flex-direction: row

.CallToDefendMedia,
.SupportUs
	margin: 1rem 0

	@media (min-width: common.$break62)
		display: flex
		flex-direction: column
		justify-content: center
		width: 100%
		margin: 0 1.875rem

.CallToDefendMedia
	position: relative

.CallToDefendMediaImage
	display: flex
	width: 100%
	height: 100%
	object-fit: cover

.CallToDefendMediaLink
	display: flex
	width: 100%
	font-size: 24px
	line-height: 28px
	letter-spacing: -0.02em
	color: #FFFFFF
	padding: 1.25rem 2.5rem
	background-color: common.$red
	text-decoration: none

.SupportUs
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	border: 2px solid common.$red
	color: common.$red

.SupportUsSubtitle
	font-weight: 400
	font-size: 20px
	line-height: 28px
	text-align: center
	margin: 4rem 1rem 0

.SupportUsTitle
	font-family: var(--font-georgia)
	font-weight: 400
	font-size: 52px
	line-height: 60px
	margin-top: 1rem
	text-align: center
	padding: 0 1rem

.SupportUsLinks
	display: flex
	flex-wrap: wrap
	justify-content: center
	margin: 3.5rem 0.5rem

.SupportUsLink
	padding: 1rem 0.75rem
	border: 1px solid common.$red
	border-radius: 5px
	margin: 0.5rem
	font-weight: 600
	font-size: 16px
	line-height: 19px
	color: common.$red
	text-decoration: none
	&:hover
		background-color: common.$red
		color: #fff

.Wrapper
	background-color: #000
	color: #fff
	width: 100%
	padding: 4.25rem 0 2.25rem

.WrapperIn
	display: flex
	flex-direction: column
	align-items: center

.NewsletterLabel
	cursor: pointer
	font-weight: 700
	font-size: 32px
	line-height: 36px
	margin: 4.25rem 0
	text-align: center

.InfoWrapper
	display: flex
	flex-wrap: wrap
	justify-content: center
	border-top: 1px solid #333333
	border-bottom: 1px solid #333333
	width: 100%

.Info
	padding: 1rem 1.75rem
	font-weight: 600
	font-size: 14px
	line-height: 17px
	text-decoration: none

.BottomPartWrapper
	display: flex
	flex-direction: column
	align-items: center
	justify-content: space-between
	width: 100%
	@media (min-width: common.$break48)
		flex-direction: row
		align-items: flex-end

.Column
	width: math.div(100%, 3)

.LogoWrapper
	display: flex
	flex-direction: column
	align-items: center
	margin-top: 5rem

.Logo
	position: relative
	display: flex
	width: 100%
	height: 108px
	@media (min-width: common.$break48)
		width: 160px
		height: 53px

.GdprWrapper
	display: flex
	flex-wrap: wrap
	justify-content: center
	align-items: center
	margin-top: 1rem

.Gdpr,
.Cookies
	font-size: 11px
	line-height: 12px
	text-align: center
	color: #8E8B8B
	margin-top: .25rem

.Cookies
	background-color: transparent
	border: none
	margin-left: 0.5rem
	text-decoration: underline
	&:hover
		text-decoration: none

.Socials
	display: flex
	justify-content: center
	margin-top: 2rem
	@media (min-width: common.$break48)
		margin-top: 0
		justify-content: flex-end

.Social
	color: #fff
	font-size: 2rem
	padding: 0 1.25rem
