@use 'sass:math'

.Wrapper
	display: grid
	// grid-template-columns: 1fr 4fr
	column-gap: 9%
	@media (min-width: 62rem)
		grid-template-columns: 1fr 4fr

.FormWrapper
	display: flex
	flex-direction: column

.Form
	display: grid
	gap: 2.5rem
	// grid-template-columns: repeat(4, 1fr)
	padding: 3rem 2.5rem
	background-color: #ffffff
	border: .0625rem solid #000000
	flex-grow: 1
	width: fit-content

	@media (min-width: 62rem)
		grid-template-columns: repeat(3, 1fr)
		gap: 5rem
		padding: 5rem 3.75rem

.LeftColumn
	display: flex
	flex-direction: column
	gap: 1.25rem

	@media (min-width: 62rem)
		grid-column: span 2

.Options
	display: flex
	flex-wrap: wrap
	gap: .625rem

	&.view_vertical
		flex-direction: column

.BoxLabel
	text-transform: uppercase
	font-size: .75rem
	line-height: math.div(15, 12)
	font-weight: 600
	min-height: math.div(15em, 12)
	margin-bottom: 1em

.Legend
	display: flex
	flex-direction: column
