@use 'common'
@use 'sass:math'

.wrapper
	margin: 3em auto
	border: solid 1px #ece7e1
	background: #faf8f7
	padding: 0.75em 1em

	&.view-type-success
		background: #eaf7ea
		border-color: #d2e8d2
	
	&.view-type-error
		background: #f7eaea
		border-color: #e8d2d2
