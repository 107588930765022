@use 'common'
@use 'sass:math'

.Wrapper
	background-color: #fff
	padding: 3rem 0

.Signature
	border-bottom: solid 1px #ece7e1
	padding: 0.5em 0
