@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	flex-direction: column
	align-items: center

.NewsPosts
	display: flex
	flex-wrap: wrap

.Pagination
	margin: 3em auto 5em
	padding-top: 1em
	max-width: 32em
	text-align: center
	border-top: solid 1px #D9D9D9
