@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions
	&-arrow
		+normalizeSize(12, 12)
	&-burger
		+normalizeSize(30, 30)
	&-cross
		+normalizeSize(10, 11)
	&-email
		+normalizeSize(30, 30)
	&-facebook
		+normalizeSize(30, 30)
	&-linkedin
		+normalizeSize(30, 30)
	&-share
		+normalizeSize(30, 30)
	&-search
		+normalizeSize(17, 18)
	&-twitter
		+normalizeSize(30, 30)
