@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	flex-direction: column

.Item
	padding: 1.5625rem
	border-bottom: 1px solid #D9D9D9
	@media (min-width: common.$break48)
		padding: 3.125rem 3.75rem

.Title
	font-weight: 600
	font-size: 12px
	line-height: 15px
	letter-spacing: 0.07em
	text-transform: uppercase
	padding-bottom: 1.25rem

.TextWrapper
	display: flex
	@media not all and (min-width: common.$break48)
		flex-direction: column

.Text
	font-weight: 400
	font-size: 1rem
	line-height: 1.5rem
	letter-spacing: -0.01em
	@media (min-width: common.$break48)
		font-size: 20px
		line-height: 28px
		width: 50%

.Text + .Text
	@media not all and (min-width: common.$break48)
		margin-top: 2rem
