@use 'common'

.wrapper
	--ContentRenderer-gap: 3.5rem
	@media (min-width: common.$break48)
		--ContentRenderer-gap: 7rem

.section

	&:not(.is_wysiwyg) + &,
	& + &:not(.is_wysiwyg)
		padding-top: var(--ContentRenderer-gap)

	&:last-child
		padding-bottom: var(--ContentRenderer-gap)

	& + &,
	*:not(.section) + &,
	& + *:not(.section)
		&.is_wysiwyg
			padding-top: 0

		&.is_reference_carousel
			padding-top: 1.25rem

		&.is_reference_fileOrLink,
		&.is_reference_image,
		&.is_reference_youtubeVideo,
		&.is_reference_callToDefendMediaForm,
		&.is_reference_table,
		&.is_reference_projectCategory,
		&.is_reference_spotifyEmbed
			padding-top: 1rem

	[data-contember-type="table"]
		border-spacing: 0
		border-collapse: collapse

	[data-contember-type="tableRow"]
		//

	[data-contember-type="tableCell"]
		padding: 0.9375em

	[data-contember-type="scrollTarget"]
		scroll-margin-top: 9.375rem

.notImplemented
	border: 0.0625rem solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize
