@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	align-items: stretch
	width: 100%
	text-decoration: none
	@media (min-width: common.$break48)
		width: 50%
	@media (min-width: common.$break62)
		width: math.div(100%, 3)

.Container
	display: flex
	flex-direction: column
	margin: 1rem 0
	border-radius: 3px
	overflow: hidden
	width: 100%
	@media (min-width: common.$break48)
		margin: 1rem 1.75rem

.Image
	position: relative
	width: 100%
	aspect-ratio: 16/9

.Name
	margin-top: 0.5rem

.LeadParagraph
	padding: 1.25rem 0

.SupportLabel,
.DateLabel
	font-weight: 700

.Support,
.Date
	font-size: 1rem
	line-height: 1.25rem
	letter-spacing: -0.01em
