@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	flex-wrap: wrap
	margin: 0 -0.5rem

.Image
	display: flex
	justify-content: center
	align-items: center
	line-height: 0
	width: 50%
	padding: 0.5rem

.ImageIn
	border: none
	background-color: transparent
