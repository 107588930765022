@use 'common'
@use 'sass:math'

.Wrapper
	//

.RedStrip
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	min-height: 7.5rem
	background-color: common.$red
	color: #fff
	font-family: var(--font-georgia)
	font-size: 1.5rem
	line-height: 2rem
	text-align: center
	letter-spacing: -0.02em
	padding: 2rem
	margin-top: 2rem
	@media (min-width: common.$break48)
		margin-top: 0
		font-size: 2rem
		line-height: 2.5rem

.ChildPageLinks
	display: flex
	flex-wrap: wrap
	justify-content: center
	margin-bottom: 35px

.ChildPageLink
	text-decoration: none
	font-weight: 600
	font-size: 16px
	line-height: 19px
	margin: 1rem

.isActive
	color: common.$red

.CategoryMenuName
	display: flex
	gap: 1rem
	padding: 1rem
	text-align: center
	justify-content: center
	font-weight: 600
	text-decoration: none
	transition: 0.2s ease-in-out color
	&:hover
		color: red

.CategoryName
	display: flex
	gap: 1rem
	padding-bottom: 1rem
	font-weight: bold
	font-size: 1.25rem
	line-height: 1.75rem
	color: #ED433D

.CategoriesStyle
	padding-bottom: 3rem
