@use 'common'
@use 'sass:math'

.Wrapper
	border: solid 1px #aeaeae
	background: #fbfbfb
	padding: 1rem 1rem 1.25rem
	font-family: var(--font-georgia)
	margin: 1em 0

.FormFields
	display: flex
	flex-direction: column
	@media (min-width: common.$break48)
		flex-direction: row
		margin-left: -0.5rem
.FormField
	display: flex
	flex-direction: column
	width: 100%
	@media (min-width: common.$break48)
		width: 50%
		padding: 0.5rem

.Input
	margin: 0.5em 0
	padding: 0.25em 0.5em
	border: solid 1px #ddd
	font-size: 1.25rem

.CheckboxWrapper
	display: flex
	margin: 0 0 1em

.Text
	margin: 1em 0
	a
		text-decoration-color: common.$red

.Button
	background: #000
	border: none
	color: #fff
	padding: 0.25em 0.5em
	font-size: 20px
	font-family: inherit
	margin-top: 0.5rem

.ListOfSupporters
	display: flex
	font-family: var(--font-georgia)
	font-size: 20px
	text-decoration: underline
	text-transform: uppercase
	text-decoration-color: common.$red
	font-weight: 700
	margin-top: 1.5rem
	width: fit-content
