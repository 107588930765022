@use 'common'
@use 'sass:math'

.Wrapper
	//

.Content
	max-width: 44rem
	margin: auto

.Image
	display: flex
	justify-content: center
	line-height: 0

.ImageCaptionWrapper
	display: flex
	justify-content: space-between

.BackButton
	display: flex
	text-decoration: none
	margin-top: .75rem

.BackIcon
	display: flex
	transform: rotate(-180deg)

.BackLabel
	padding-left: 0.5rem

.ImageCaption
	display: flex
	justify-content: flex-end
	color: #8B8B8B
	font-weight: 500
	font-size: 12px
	line-height: 16px
	margin-top: 0.25rem

.ShareIcons
	display: flex
	justify-content: flex-end

.InfoWrapper
	display: flex
	flex-wrap: wrap
	margin-top: 4.25rem

.PublishedAt,
.Category
	font-weight: 600
	font-size: 12px
	line-height: 18px
	margin: 0 1.5rem 0.75rem 0

.PublishedAt
	color: #555

.Category
	text-transform: uppercase
	color: common.$red

.Title
	font-family: var(--font-georgia)
	font-weight: 400
	font-size: 52px
	line-height: 60px
	letter-spacing: -0.02em
	margin: 0 0 1.375rem

.TitleBorder
	width: 5.625rem
	height: 1px
	background-color: common.$red
	margin-left: -1rem
	@media (min-width: 40rem)
		margin-left: -2rem

.LeadParagraph
	font-family: var(--font-georgia)
	font-weight: 400
	font-size: 32px
	line-height: 40px
	letter-spacing: -0.02em
	margin: 3.125rem 0

.NewsPosts
	margin-bottom: 6.25rem
