@use 'common'
@use 'sass:math'

.Container
	position: fixed
	bottom: 0
	background-color: #2f2f2f
	color: #fff
	z-index: 2
	padding: 1rem
	display: none
	font-family: var(--font-georgia)
	width: 100%
	@media (min-width: 40rem)
		padding: 2rem

	&.is_Open
		display: flex
		flex-direction: column

:global(.cookies-agreed) .Container
	display: none

.Wrapper

	max-width: 1400px
	margin: auto

.CookiesDefault
	display: none
	&.is_Open
		display: flex
		flex-direction: column

.Btn
	display: inline-block
	background: #dc3e38
	color: #fff
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none
	border: none
	font-family: inherit
	font-size: inherit
	line-height: 1.2
	padding: 0.5em 1.5em
	text-decoration: none
	margin-right: 1rem
	width: fit-content
	&:hover
		background-color: common.$red

.Close
	display: flex
	width: fit-content
	margin-left: auto
	background: transparent
	border: none
	color: #fff
	padding: 0
	&:hover
		background-color: transparent
		color: common.$red

.Title
	margin-top: 0

.Text
	font-size: .9375rem
	line-height: 1.375rem
	a
		text-decoration-color: common.$red

.Actions
	display: flex
	margin-top: 1rem

.CookieBarSettings
	display: none
	&.is_settingsOpen
		display: flex
		flex-direction: column

// .InputWrapper
// 	margin-bottom: 1rem

.Input
	font-size: 1.25rem

.InputText
	font-size: 0.75rem
	margin: 0.5rem 0 1rem
