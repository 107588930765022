@use 'common'
@use 'sass:math'

.Container
    text-align: center

.Info
    font-weight: bold
    margin-bottom: 2.5em
    font-size: 1em * math.div(12, 16)

.Actions
    margin: .5em

.Button
    border-radius: 5px
    background-color: common.$red
    color: #F8F6EE
    padding: 1em 1.5em
    text-decoration: none
    font-weight: 600
    display: inline-block

    &:hover
        opacity: 0.75
