@use 'common'
@use 'sass:math'

.Wrapper
	width: 100%
	text-decoration: none
	@media (min-width: common.$break48)
		width: 50%
	@media (min-width: common.$break62)
		width: math.div(100%, 3)

.WrapperIn
	margin: 1rem

.Image
	position: relative
	width: 100%
	aspect-ratio: 16/9
	background: common.$beige

.Date
	font-weight: 600
	font-size: 12px
	line-height: 18px
	letter-spacing: 0.07em
	color: #555555
	margin-top: 0.5rem

.Title
	font-weight: 700
	font-size: 24px
	line-height: 28px
	letter-spacing: -0.02em
	margin: .875rem 0 .75rem

.LeadParagraph
	font-size: 16px
	line-height: 24px
	letter-spacing: -0.01em

.Categories
	margin-top: 1rem

.Category
	font-weight: 600
	font-size: 12px
	line-height: 15px
	letter-spacing: 0.07em
	text-transform: uppercase
	color: common.$red
