@use 'common'
@use 'sass:math'

.Wrapper
	margin: 2rem 0
	width: 100%
	@media (min-width: common.$break48)
		margin: 2rem
		width: calc(50% - 4rem)
	@media (min-width: common.$break62)
		width: calc(100% / 3 - 4rem)

.Image
	position: relative
	aspect-ratio: calc(288px / 164px)

.Name
	font-weight: 700
	font-size: 24px
	line-height: 28px
	letter-spacing: -0.02em
	margin: 1rem 0
	@media (min-width: common.$break48)
		margin: 2rem 0 1rem
