@use 'common'
@use 'sass:math'

.Title
	font-family: var(--font-georgia)
	text-align: center
	font-weight: 400
	font-size: 52px
	line-height: 60px
	margin-top: 3.125rem
	margin-bottom: 0rem

.TitleLine
	border-bottom: 1px solid #D9D9D9
	width: 100%
	margin-bottom: 1rem
