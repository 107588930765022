@use 'common'

.Wrapper
	display: flex
	flex-direction: column

.Text
	color: #555555
	font-weight: 600
	font-size: 12px
	line-height: 18px
	letter-spacing: 0.07em
	padding-bottom: 0.75rem

.Line
	width: 100%
	border-bottom: 1px solid #D9D9D9
