@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	flex-direction: column
	align-items: center
	background-color: #fff

.Title
	font-size: 12px
	line-height: 15px
	letter-spacing: 0.07em
	font-weight: 600
	color: common.$red
	padding: 1.25rem 1rem
	text-transform: uppercase
	text-align: center

.Reviews
	display: flex
	align-items: center
	width: 100%
	justify-content: space-between

.Slides
	+common.hideHorizontalScrollBar
	display: flex
	overflow-x: auto
	margin: 1.5625rem 0 2.8125rem
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	max-width: 55rem
	@media (min-width: common.$break48)
		margin: 3.4375rem 0 2.8125rem
	@media (min-width: common.$break62)
		margin: 6.875rem 0 2.8125rem

.Slide
	display: flex
	flex-direction: column
	width: 100%
	flex-shrink: 0
	scroll-snap-align: center
	@media (min-width: common.$break48)
		padding: 0 1.5rem
		flex-direction: row

.Image
	position: relative
	aspect-ratio: 1
	width: 100%
	margin: 0 auto 1rem
	@media (min-width: common.$break30)
		width: 50%
	@media (min-width: common.$break48)
		margin-bottom: auto
		height: auto
		width: 25%

.Content
	@media (min-width: common.$break48)
		margin-left: 1.875rem
		width: 75%
	@media (min-width: common.$break62)
		margin-left: 3.75rem

.Review
	font-family: var(--font-georgia)
	font-size: 20px
	line-height: 28px
	letter-spacing: -0.02em
	@media (min-width: common.$break48)
		font-size: 24px
		line-height: 32px

.ReviewLine
	border-top: 2px solid common.$red
	width: 5rem
	margin-top: 1rem
	@media (min-width: common.$break48)
		margin-left: -1rem
	@media (min-width: common.$break62)
		margin-left: -2rem

.Name
	font-weight: 600
	font-size: 16px
	line-height: 19px
	margin-top: 1.5rem
	@media (min-width: common.$break48)
		margin-top: 2.25rem

.Position
	font-weight: 500
	font-size: 12px
	line-height: 16px
	color: #8B8B8B
	margin-top: 0.5rem

.ArrowWrapperPrevious
	padding: 0 1rem
	@media (min-width: common.$break48)
		padding: 0 0 0 2rem
	@media (min-width: common.$break62)
		padding: 0 0 0 4rem
.ArrowWrapper
	padding: 0 1rem
	@media (min-width: common.$break48)
		padding: 0 2rem 0 0
	@media (min-width: common.$break62)
		padding: 0 4rem 0 0
.Arrow
	display: flex
	font-size: 1rem
	border: none
	background-color: transparent
	border-radius: 50%
	visibility: hidden
	padding: 0
	color: #000
	padding: 0.25rem
	border: 2px solid

	&:hover
		color: #fff

.Previous
	transform: rotate(-180deg)

.isVisible
	visibility: visible
