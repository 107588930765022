@use 'common'
@use 'sass:math'

.Wrapper
	display: flex
	justify-content: space-between
	align-items: center
	margin-top: 1rem
	@media (min-width: common.$break48)
		margin-top: 1.875rem

.Column
	width: math.div(100%, 3)
	@media (min-width: common.$break48)
		width: 50%

.Burger,
.Close
	background: transparent
	border: none
	display: flex
	padding: 0
	@media (min-width: common.$break48)
		display: none

.Burger
	font-size: 2rem
	color: #000
	width: math.div(100%, 3)

.SearchWrapperMobile
	margin: 2rem 0
	@media (min-width: common.$break48)
		display: none

.SearchWrapperDesktop
	display: none
	@media (min-width: common.$break48)
		display: block
		margin-top: 1rem
		min-width: 250px

.Close
	font-size: 1rem
	color: #fff
	margin-left: auto
	padding-top: 1rem

.Logo
	position: relative
	display: flex
	height: 4.25rem
	@media (min-width: common.$break48)
		height: 6.25rem
		margin-right: auto
		width: inherit

.SpecialLinkWrapper
	display: flex
	flex-direction: column
	justify-content: flex-end
	align-items: flex-end

.SpecialLink
	border-radius: 5px
	background-color: common.$red
	color: #F8F6EE
	padding: 0.5rem
	text-decoration: none
	font-weight: 600
	font-size: .75rem
	width: fit-content
	&:hover
		opacity: 0.75
	@media (min-width: common.$break48)
		padding: 1rem 1.25rem
		font-size: 1rem
		line-height: 19px
		position: relative
		bottom: 0
		right: 0

.MobileHeader
	display: none

.MobileHeaderOpen
	display: block
	position: absolute
	inset: 0
	z-index: 2
	background-color: #000
	color: #fff
	height: min-content
	padding: 0 1rem 6.25rem

	@media (min-width: common.$break48)
		display: none

.SpecialLinkMobile,
.NewsletterLabelMobile
	font-weight: 700
	font-size: 20px
	line-height: 40px
	cursor: pointer

.SpecialLinkMobile
	display: flex
	margin: 3.75rem 0 1.5rem
	text-decoration: none

.NewsletterLabelMobile
	margin-bottom: 3.75rem

.LinksWrapper
	display: none
	@media (min-width: common.$break48)
		display: block
		border-top: 1px solid #D9D9D9
		border-bottom: 1px solid #D9D9D9
		margin-top: 3.125rem

.LinksWrapperIn
	display: flex
	justify-content: space-between
	align-items: center
	padding: 0.5rem

.Socials
	display: flex

.Socials,
.NewsletterLabel
	@media (min-width: common.$break48)
		width: 20%

.Social
	display: flex
	font-size: 2rem
	color: #fff
	margin-right: 1rem
	@media (min-width: common.$break48)
		font-size: 1.25rem
		color: #000
	@media (min-width: common.$break62)
		margin-right: 2rem

.Links
	display: flex
	flex-direction: column
	flex-wrap: wrap
	justify-content: center
	@media (min-width: common.$break48)
		width: 60%
		flex-direction: row

.LinksSecondLevel
	margin-bottom: 1rem
	display: none

	&:has(.is_active)
		display: flex

.LinksThirdLevel
	display: none

	&:has(.is_active)
		display: flex

.Link,
.NewsletterLabel
	cursor: pointer
	color: inherit
	text-decoration: none
	font-weight: 600
	&:hover
		color: common.$red

.NewsletterLabel
	font-size: 16px
	line-height: 2rem

.Link
	font-size: 2rem
	line-height: 4.25rem
	@media (min-width: common.$break48)
		font-size: 16px
		line-height: 19px
		padding: 0.5rem 0.75rem

	&.is_active
		color: #ED433D

.LinkSecondLevel
	font-weight: 700
	font-size: 24px
	line-height: 48px
	letter-spacing: -0.02em
	color: #AAAAAA
	text-decoration: none

	&.is_active
		color: #ED433D

.LinkThirdLevel
	font-weight: 700
	font-size: 20px
	line-height: 28px
	letter-spacing: -0.02em
	color: #555555
	text-decoration: none

	&.is_active
		color: #ED433D
.NewsletterLabel
	@media (min-width: common.$break48)
		text-align: right
		line-height: 19px
		padding: 0
