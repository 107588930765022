@use "sass:math"
	
.List
	margin-top: 3rem
	margin-bottom: 3rem
	display: grid
	row-gap: 1rem

	@media (min-width: 48rem)
		grid-template-columns: repeat(2, 1fr)
		row-gap: 1.875rem
		column-gap: 2.5rem

	@media (min-width: 75rem)
		margin-top: 5rem
		grid-template-columns: repeat(3, 1fr)
		row-gap: 3.3125rem
		column-gap: 3.75rem

.Item
	position: relative
	display: flex
	flex-direction: column
	align-items: center
	background-color: #ffffff
	padding: 2.5rem 4.1875rem

.Link
	position: absolute
	inset: 0
	z-index: 1

	&:hover,
	&:focus
		& ~ .Content .TileTitle
			text-decoration: underline

	&:hover
		text-decoration: underline

.ImageWrapper
	position: relative
	width: 100%
	max-width: 13.5rem
	aspect-ratio: math.div(216, 121)

.Image
	width: 100%
	height: 100%
	object-fit: contain

.Rating
	position: absolute
	top: 100%
	left: 50%
	transform: translate(-50%, -50%)
	font-size: 1.5rem

.Content
	margin-top: 2.5rem
	text-align: center

.TileTitle
	margin: 0 0 0.5em

.Site
	position: relative
	z-index: 2
	text-decoration: none

	&:hover,
	&:focus
		text-decoration: underline
