@use 'common'
@use 'sass:math'

.Wrapper
	position: absolute
	top: 0
	left: 0
	right: 0
	display: flex
	flex-direction: column
	align-items: center

.Close
	display: flex
	margin: 1rem 1rem 0 auto
	border: none
	background: transparent
	font-size: 1rem

.FormFields
	display: flex
	flex-direction: column
	width: 60%

.FormField
	display: flex
	align-items: center
	margin: 1.875rem 0
	border-bottom: 1px solid #AAAAAA
	font-weight: 600

.Input
	width: 100%
	border: none
	// border-bottom: 1px solid #AAAAAA
	padding: 0.5rem
	font-size: 1rem
	margin-left: 0.25rem

	&:focus-visible
		outline: none
		border-bottom: 1px solid red
		margin-bottom: -1px

.Gdpr
	font-weight: 600
	font-size: 12px
	line-height: 15px

.Button
	font-weight: 600
	font-size: 16px
	line-height: 19px
	text-align: center
	color: #F8F6EE
	padding: 15px 20px
	background: common.$red
	border-radius: 5px
	border: none
	width: fit-content
	margin-left: auto
