
@keyframes dots
    93.75%
    r: 3px

    100%
        r: 3px

    46.875%
        r: 0.2px

.Wrapper
    display: inline-flex
    align-items: center
    justify-content: center
    width: 1em
    height: 1em
    line-height: 1
    overflow: hidden
    vertical-align: baseline
    transform: translateY(.1em)

.Dot
    animation: dots 0.8s linear infinite

    &.a
        animation-delay: -0.65s

    &.b
        animation-delay: -0.5s
