$icon-size: 1em
$horizontal-offset: 1.25rem
	
.Wrapper
	position: relative
	display: flex

.Input
	width: 100%
	padding: 1rem $horizontal-offset 1rem calc(#{$horizontal-offset} + #{$icon-size} + .25rem)
	border: .0625rem solid #000000
	border-radius: .1875rem


	&::placeholder
		color: #aaaaaa

.Icon
	position: absolute
	top: 50%
	display: flex
	color: #aaaaaa
	font-size: $icon-size
	left: $horizontal-offset
	transform: translateY(-50%)
